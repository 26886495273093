import {FC, useContext, useEffect, useState} from 'react'
import {Grid, GridColumn as Column, GridCellProps} from '@progress/kendo-react-grid'
import React from 'react'
import {IProjectCustomField} from '../../../../models/project-custom-field'
import {FIELD_TYPE, PROJECT_CUSTOM_FIELD} from '../../../../constant/project-default'
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns'
import {ProjectContext} from '../../../../context/ProjectContext'
import {FieldArrayWithId, UseFieldArrayReplace} from 'react-hook-form'
import {IProjectData} from '../../../../models/project-model'
import {AccessControlProvider} from '../../../../../../../_metronic/layout/core/AccessControlProvider'
import {PAGE_PERMISSION} from '../../../../../roles/models/role-model'
import {hasPermission} from '../../../../../../shared/service/utils'
import {usePageData} from '../../../../../../../_metronic/layout/core'

type IProps = {
  projectCustomFields: FieldArrayWithId<IProjectData, 'projectCustomFields', 'keyIdx'>[]
  replaceProjectCustomFields: UseFieldArrayReplace<IProjectData, 'projectCustomFields'>
  setDataUpdated?: (value: boolean) => void
}

const CustomFields: FC<IProps> = ({
  projectCustomFields,
  replaceProjectCustomFields,
  setDataUpdated,
}) => {
  const {showToastrNotification} = useContext(ProjectContext)
  const {
    currentUser: {activePages},
  } = usePageData()
  const [data, setData] = useState<IProjectCustomField[]>(projectCustomFields)

  useEffect(() => {
    setData(projectCustomFields)
  }, [projectCustomFields])

  const customTextField = (props: GridCellProps) => {
    return (
      <td key={props.dataIndex}>
        <input
          type='text'
          className='form-control'
          defaultValue={props.dataItem[props.field || '']}
          disabled={!hasPermission(PAGE_PERMISSION.PROJECT_DETAILS_UPDATE, activePages)}
          onBlur={(event) => {
            var newDataItem = props.dataItem
            if (
              props.field === 'field' &&
              event.target.value !== '' &&
              props.dataItem.databaseField === ''
            ) {
              newDataItem = {
                ...newDataItem,
                databaseField: toDatabaseField(event.target.value),
              }
            }
            data[props.dataIndex] = {
              ...newDataItem,
              [props.field || '']: event.target.value,
            }
            replaceProjectCustomFields([...data])
          }}
        />
      </td>
    )
  }

  const customDropdownField = (props: GridCellProps) => {
    return (
      <td key={props.dataIndex}>
        <DropDownList
          style={{width: '300px'}}
          data={FIELD_TYPE}
          defaultValue={props.dataItem[props.field || '']}
          disabled={!hasPermission(PAGE_PERMISSION.PROJECT_DETAILS_UPDATE, activePages)}
          onChange={(event: DropDownListChangeEvent) => {
            data[props.dataIndex] = {
              ...props.dataItem,
              [props.field || '']: event.target.value,
            }
            replaceProjectCustomFields([...data])
          }}
        />
      </td>
    )
  }
  const customActionButton = (props: GridCellProps) => {
    return (
      <td key={props.dataIndex} className='text-center'>
        <i
          className='fa fa-times'
          onClick={(_) => {
            const newData = [...data] // Create a copy of the original array
            // Remove the element at the specified index
            newData.splice(props.dataIndex, 1)
            replaceProjectCustomFields(newData)
            showToastrNotification(
              'The field has been deleted. Your changes will take effect after you click Save Changes.',
              'info'
            )
          }}
        ></i>
      </td>
    )
  }

  const addFieldHandler = () => {
    if (!isValidField()) {
      showToastrNotification(
        'Please ensure no duplicate and all required fields are completed before proceeding to adding a new field.',
        'info'
      )
      return
    }
    var newdata = [
      ...data,
      {
        ...PROJECT_CUSTOM_FIELD,
      },
    ]
    replaceProjectCustomFields(newdata)
  }

  const isValidField = (): boolean => {
    const isValidInput =
      data.length === 0 || data.filter((item) => item.field.trim() === '').length === 0

    // Check for duplicate fields
    const fieldsSet = new Set<string>()
    const hasDuplicateField = data.some((item) => {
      const trimmedField = item.field.toLowerCase().trim()
      if (fieldsSet.has(trimmedField)) {
        return true // Found a duplicate field
      } else {
        fieldsSet.add(trimmedField)
        return false
      }
    })

    return isValidInput && !hasDuplicateField
  }

  const toDatabaseField = (field: string): string => {
    let splitField = field.split(' ')
    splitField = splitField.map((item) => item.toLowerCase())
    const fieldName = 'cust_' + splitField.join('_')
    return fieldName
  }

  return (
    <React.Fragment>
      <Grid resizable={true} data={data}>
        {hasPermission(PAGE_PERMISSION.PROJECT_DETAILS_UPDATE, activePages) && (
          <Column title=' ' width={60} cell={customActionButton} />
        )}
        <Column field='field' title='Field' cell={customTextField} />
        <Column field='type' title='Field Type' cell={customDropdownField} />
        <Column field='databaseField' title='Database Field' cell={customTextField} />
      </Grid>
      <AccessControlProvider allowedPermission={PAGE_PERMISSION.PROJECT_DETAILS_UPDATE}>
        <button type='button' className='btn btn-primary mb-5 mt-5' onClick={addFieldHandler}>
          + Add Field
        </button>
      </AccessControlProvider>
    </React.Fragment>
  )
}

export {CustomFields}
